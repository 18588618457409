import React, { useState } from "react";

const EditReportModal = ({ report, doctors, onClose, onSave }) => {
  const [editedFileName, setEditedFileName] = useState(report.fileName || "");
  const [selectedDoctor, setSelectedDoctor] = useState(
    report.doctor?.name || ""
  );
  const [newFile, setNewFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewFile(file);
  };

  const handleSave = () => {
    console.log("Before Save:", report);

    const updatedDoctor = selectedDoctor
      ? { id: report.doctor?.id || null, name: selectedDoctor }
      : report.doctor;

    const updatedReport = {
      ...report,
      fileName: editedFileName || report.fileName,
      doctor: updatedDoctor,
      newFile: newFile || report.newFile,
    };

    console.log("After Save:", updatedReport);

    onSave(updatedReport);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">Edit Report</h2>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            File Name:
          </label>
          <input
            type="text"
            value={editedFileName}
            onChange={(e) => setEditedFileName(e.target.value)}
            className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Doctor:
          </label>
          <select
            value={selectedDoctor}
            onChange={(e) => setSelectedDoctor(e.target.value)}
            className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="" disabled>
              Select Doctor
            </option>
            {doctors.map((doctor) => (
              <option key={doctor.id} value={doctor.name}>
                {doctor.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Report File:
          </label>
          <input
            type="file"
            onChange={handleFileChange}
            className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
          />
          {newFile ? (
            <p className="text-sm text-gray-500 mt-2">{newFile.name}</p>
          ) : (
            <p className="text-sm text-gray-500 mt-2">
              Current file: {report.fileName}
            </p>
          )}
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditReportModal;
