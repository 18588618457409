import axios from "axios";
import React, { useState } from "react";

const FindAndUpdatePatient = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [patientData, setPatientData] = useState(null);
  const [newReports, setNewReports] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [reportStatus, setReportStatus] = useState(null);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleFindPatient = async () => {
    try {
      const response = await axios.get(
        `https://www.report.jamalpurcentralhospital.com/api/patient/phone/${phoneNumber}`
      );
      setPatientData(response.data);

      if (response.data.reports && response.data.reports.length > 0) {
        setReportStatus("Uploaded");
      } else {
        setReportStatus("Not Uploaded");
      }

      setErrorMessage(null);
    } catch (error) {
      setPatientData(null);
      setErrorMessage("Patient not found");
      setReportStatus(null);
      console.error("Error fetching patient data:", error.message);
    }
  };

  const handleReportChange = (e) => {
    const files = Array.from(e.target.files);
    setNewReports(files);
  };

  const handleUpdateReports = async () => {
    try {
      if (patientData) {
        const formData = new FormData();
        newReports.forEach((report) => {
          formData.append("reports", report);
        });

        await axios.post(
          `https://www.report.jamalpurcentralhospital.com/api/report/upload-multiple/${patientData.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setErrorMessage(null);
        await handleFindPatient();

        setPhoneNumber("");
        setNewReports([]);
      } else {
        setErrorMessage("No patient data available");
      }
    } catch (error) {
      setErrorMessage("Error updating patient reports");
      console.error("Error updating patient reports:", error);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-center">
        Find and Update Patient
      </h2>

      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Enter Phone Number (Without "+88")
        </label>
        <input
          type="text"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
        />
        <button
          type="button"
          onClick={handleFindPatient}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 focus:outline-none focus:shadow-outline"
        >
          Find Patient
        </button>
      </div>

      {patientData && (
        <div className="mt-4">
          <p className="font-semibold">Patient Details:</p>
          <p>Name: {patientData.name}</p>
          <p>Age: {patientData.age || "-"}</p>
          <p>Doctor Name: {patientData.doctorName || "-"}</p>
          <p>Email: {patientData.email || "-"}</p>

          <p className="mt-2">Report Status: {reportStatus || "Not Checked"}</p>

          <div className="mt-4">
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Update Reports
            </label>
            <input
              type="file"
              multiple
              onChange={handleReportChange}
              className="appearance-none rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            />
            <button
              type="button"
              onClick={handleUpdateReports}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2 focus:outline-none focus:shadow-outline"
            >
              Update Reports
            </button>
          </div>
        </div>
      )}

      {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
    </div>
  );
};

export default FindAndUpdatePatient;
