import React from "react";

const PatientModal = ({ patients, onClose }) => {
  return (
    <div className="absolute top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white px-16 py-12 rounded-lg">
        <h2 className="text-lg font-semibold mb-3">Patient List</h2>
        <ul className="mb-3">
          {patients.map((patient, index) => (
            <li key={index}>{patient.name}</li>
          ))}
        </ul>
        <button
          onClick={onClose}
          className="mt-2 px-[12px] py-1 bg-red-500 text-white rounded hover:bg-red-700"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PatientModal;
