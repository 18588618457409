import React from "react";
import { Link, Outlet } from "react-router-dom";
import add from "../../../Assets/add.png";
import dashboard from "../../../Assets/dashboard.png";
import AddDoctor from "../../../Assets/doctor1.png";
import avater from "../../../Assets/user-avater.png";

const AdminSidebar = () => {
  return (
    <div className="flex h-screen">
      {/* Ensures the overall container is full height and prevents unnecessary overflow */}
      <div className="bg-[#effbff] text-black fixed h-full w-[14%] flex flex-col items-center overflow-auto">
        {/* Fixed sidebar with full height and scrolling */}
        <div className="bg-[#656f92] p-4 w-full text-white">
          <img
            src={avater}
            alt="User Avatar"
            className="w-16 h-16 rounded-full mx-auto"
          />
          <p className="text-center capitalize">Admin</p>
        </div>

        <nav className="p-2 md:p-4">
          <div className="flex items-center justify-center">
            <ul className="space-y-4">
              <li>
                <img src={dashboard} alt="Dashboard" className="w-6 h-6 mr-2" />
                <Link to="/siteadmin/dashboard">
                  <p className="font-semibold">Dashboard</p>
                </Link>
              </li>
              <li>
                <img src={AddDoctor} alt="Doctors" className="w-6 h-6 mr-2" />
                <Link to="/siteadmin/view-doctor">
                  <p className="font-semibold">Doctors</p>
                </Link>
              </li>
              <li>
                <img src={add} alt="Patients" className="w-6 h-6 mr-2" />
                <Link to="/siteadmin/view-patients">
                  <p className="font-semibold">Patients</p>
                </Link>
              </li>
              {/* <li>
                <img src={view} alt="View Report" className="w-6 h-6 mr-2" />
                <Link to="/siteadmin/manage">
                  <p className="font-semibold">View Report</p>
                </Link>
              </li>
              <li>
                <img src={doctor} alt="Add Report" className="w-6 h-6 mr-2" />
                <Link to="/siteadmin/add-report">
                  <p className="font-semibold">Add Report</p>
                </Link>
              </li> */}
            </ul>
          </div>
        </nav>
      </div>
      <div className="flex-1 ml-[14%]">
        {/* Margin-left to account for the width of the fixed sidebar */}
        <Outlet />
      </div>
    </div>
  );
};

export default AdminSidebar;
