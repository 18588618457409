import axios from "axios";
import React, { useEffect, useState } from "react";

const EditPatientModal = ({ patient, onClose, onUpdate }) => {
  const [editedPatient, setEditedPatient] = useState({ ...patient });
  const [doctors, setDoctors] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await axios.get(
          "https://www.report.jamalpurcentralhospital.com/api/doctor"
        );
        setDoctors(response.data);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };

    fetchDoctors();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedPatient((prevPatient) => ({ ...prevPatient, [name]: value }));
  };

  const handleDoctorSelection = (e) => {
    const { value, checked } = e.target;

    setEditedPatient((prevPatient) => {
      const updatedDoctorNames = checked
        ? [...prevPatient.doctors, value]
        : prevPatient.doctors.filter((doctorName) => doctorName !== value);

      return { ...prevPatient, doctors: updatedDoctorNames };
    });
  };

  const handleUpdate = async () => {
    try {
      const data = {
        id: editedPatient.id,
        name: editedPatient.name,
        age: editedPatient.age,
        doctorNames: editedPatient.doctors,
        mobileNumber: editedPatient.mobileNumber,
        email: editedPatient.email,
      };

      console.log(data);
      axios
        .patch(
          `https://www.report.jamalpurcentralhospital.com/api/patient/update/${editedPatient.id}`,
          data
        )
        .then((response) => {
          console.log("Data sent successfully:", response.data);
          onUpdate(response.data);
          onClose();
        })
        .catch((error) => {
          console.error("Error updating patient:", error);
        });
    } catch (error) {
      console.error("Error updating patient:", error);
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50">
      <div className="bg-white p-10 w-full max-w-md rounded">
        <h2 className="text-xl font-semibold mb-4 text-center">Edit Patient</h2>
        <form className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-600">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={editedPatient.name}
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">
              Age
            </label>
            <input
              type="text"
              name="age"
              value={editedPatient.age}
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">
              Mobile Number
            </label>
            <input
              type="text"
              name="mobileNumber"
              value={editedPatient.mobileNumber}
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">
              Email
            </label>
            <input
              type="text"
              name="email"
              value={editedPatient.email}
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">
              Select Doctors
            </label>
            <div className="relative">
              <button
                type="button"
                className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {editedPatient.doctors.length > 0
                  ? `${editedPatient.doctors.length} doctor(s) selected`
                  : "Select Doctors"}
              </button>

              {isDropdownOpen && (
                <div className="absolute mt-2 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                  <div className="max-h-48 overflow-y-auto">
                    {doctors.map((doctor) => (
                      <label
                        key={doctor.id}
                        className="flex items-center px-3 py-2 hover:bg-gray-100"
                      >
                        <input
                          type="checkbox"
                          value={doctor.name}
                          checked={editedPatient.doctors.includes(doctor.name)}
                          onChange={handleDoctorSelection}
                          className="mr-2"
                        />
                        {doctor.name}
                      </label>
                    ))}
                  </div>
                  <button
                    type="button"
                    className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 rounded-b focus:outline-none focus:shadow-outline"
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    Done
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleUpdate}
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Update
            </button>
            <button
              type="button"
              onClick={onClose}
              className="ml-2 bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPatientModal;
