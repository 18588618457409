import axios from "axios";
import React, { useEffect, useState } from "react";
import download from "../../../Assets/download.png";
import PDF from "../../../Assets/pdf.png";
import { useUser } from "../../../Contexts/UserContext";

const ViewReport = () => {
  const { state } = useUser();
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await axios.get(
          `https://www.report.jamalpurcentralhospital.com/api/report/patient/${state.user.id}`
        );
        console.log(response.data);
        setReports(response.data);
      } catch (error) {
        console.error("Error fetching reports:", error);
      }
    };

    fetchReports();
  }, [reports, state.user.id]);

  const downloadReport = async (reportId, fileName) => {
    try {
      const response = await axios.get(
        `https://www.report.jamalpurcentralhospital.com/api/patient/download-report/${state.user.id}/${reportId}`,
        { responseType: "arraybuffer" }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading report:", error);
    }
  };

  return (
    <div className="p-4 md:p-10">
      <div className="">
        <h2 className="text-2xl font-semibold mb-6 text-center">
          Patient Reports
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {reports.length > 0 ? (
            reports.map((report, index) => (
              <div
                key={report.id}
                className="flex flex-col items-center p-4 border rounded-md bg-gray-50 shadow-sm hover:shadow-md transition-shadow duration-200"
              >
                <img
                  src={PDF} // Replace with actual PDF icon path
                  alt={`Report ${index + 1}`}
                  className="w-12 h-12 mb-2"
                />
                <div className="text-center mb-4">
                  <p className="text-lg font-semibold truncate">
                    {report.fileName || `Report ${index + 1}`}
                  </p>
                  <p className="text-sm text-gray-600 truncate">
                    Doctor: {report.doctor.name || "N/A"}
                  </p>

                  <p className="text-sm text-gray-600">
                    Date: {new Date(report.reportDate).toLocaleDateString()}
                  </p>
                </div>
                <button
                  onClick={() =>
                    downloadReport(
                      report.id,
                      report.fileName || `report_${index + 1}.pdf`
                    )
                  }
                  className="flex items-center bg-green-500 text-white py-2 px-4 rounded-full mt-auto transition duration-300 hover:bg-green-600"
                >
                  <span className="mr-2">Download</span>
                  <img
                    src={download} // Replace with the actual download icon path
                    alt="Download Report"
                    className="w-4 h-4"
                  />
                </button>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500 mt-6">
              No reports available.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewReport;
