import React, { createContext, useContext, useReducer } from "react";

// Initial state
const initialState = {
  user: null,
  isAuthenticated: false,
  role: null,
};

// Action types
const SET_USER = "SET_USER";
const LOGOUT = "LOGOUT";

const userReducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload.user,
        role: action.payload.role,
        isAuthenticated: true,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        role: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

// Create a context
const UserContext = createContext();

// UserProvider component
const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to access the context
const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export { LOGOUT, SET_USER, UserProvider, useUser };
