import axios from "axios";
import React, { useState } from "react";
import pdfIcon from "../../../Assets/pdf.png";
import EditReportModal from "./EditReportModal";

const ReportItem = ({ report, onDelete, doctors, onSave, patientId }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        `https://www.report.jamalpurcentralhospital.com/api/patient/download-report/${patientId}/${report.id}`,
        { responseType: "arraybuffer" }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });

      // Attempt to get the filename from the response headers
      const contentDisposition = response.headers["content-disposition"];
      let fileName = report.fileName || "report.pdf";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length > 1) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading report:", error);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSave = (updatedReport) => {
    onSave(updatedReport);
    setIsEditing(false);
  };

  const handleCloseModal = () => {
    setIsEditing(false);
  };

  return (
    <div className="report-item bg-white shadow-md rounded-lg p-4 mb-4 flex">
      <img src={pdfIcon} alt="PDF" className="w-12 h-12 mr-4" />
      <div className="flex-1">
        <p className="font-semibold">{report.fileName || "No Filename"}</p>
        <p className="text-gray-600">{report.doctor?.name || "No Doctor"}</p>
        <div className="mt-2 flex justify-between">
          <button
            onClick={handleDownload}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
          >
            Download
          </button>
          <button
            onClick={handleEditClick}
            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-3 rounded"
          >
            Edit
          </button>
          <button
            onClick={() => onDelete(report.id)}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded"
          >
            Delete
          </button>
        </div>
      </div>
      {isEditing && (
        <EditReportModal
          report={report}
          doctors={doctors}
          onClose={handleCloseModal}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default ReportItem;
