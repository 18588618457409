import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import EditDoctorModal from "./editDoctor";
import PatientModal from "./patientModal"; // Ensure this component is ready

const DoctorsList = () => {
  const [doctors, setDoctors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [patientModalOpen, setPatientModalOpen] = useState(false);
  const [selectedPatients, setSelectedPatients] = useState([]);

  // Memoized fetchDoctors function
  const fetchDoctors = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://www.report.jamalpurcentralhospital.com/api/doctor"
      );
      setDoctors(response.data);
    } catch (error) {
      console.error("Error fetching doctors:", error);
    }
  }, []);

  useEffect(() => {
    fetchDoctors();
  }, [fetchDoctors]);

  // Memoized filtered, sorted, and paginated doctors
  const filteredDoctors = useMemo(() => {
    return doctors.filter((doctor) =>
      doctor.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [doctors, searchQuery]);

  const sortedDoctors = useMemo(() => {
    const order = sortOrder === "asc" ? 1 : -1;
    return filteredDoctors.sort((a, b) => (a.name > b.name ? order : -order));
  }, [filteredDoctors, sortOrder]);

  const paginatedDoctors = useMemo(() => {
    const startIndex = (currentPage - 1) * 5;
    const endIndex = startIndex + 5;
    return sortedDoctors.slice(startIndex, endIndex);
  }, [currentPage, sortedDoctors]);

  useEffect(() => {
    setTotalPages(Math.ceil(sortedDoctors.length / 5));
  }, [sortedDoctors]);

  // Memoized callbacks
  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
  }, []);

  const handleSearch = useCallback((query) => {
    setCurrentPage(1);
    setSearchQuery(query);
  }, []);

  const handleSortOrderChange = useCallback((newSortOrder) => {
    setSortOrder(newSortOrder);
  }, []);

  const handleEditClick = useCallback((doctor) => {
    setSelectedDoctor(doctor);
  }, []);

  const handleModalClose = useCallback(() => {
    setSelectedDoctor(null);
    setPatientModalOpen(false);
    fetchDoctors(); // Refresh the list after editing
  }, [fetchDoctors]);

  const handleDelete = useCallback(
    async (doctorId) => {
      try {
        await axios.delete(
          `https://www.report.jamalpurcentralhospital.com/api/doctor/${doctorId}`
        );
        fetchDoctors(); // Refresh the list after deletion
      } catch (error) {
        console.error("Error deleting doctor:", error);
      }
    },
    [fetchDoctors]
  );

  const showPatients = useCallback((patients) => {
    setSelectedPatients(patients);
    setPatientModalOpen(true);
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-10">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-3xl font-semibold mb-8 text-center">
            Doctors List
          </h2>
          <div className="mb-6 flex justify-between items-center">
            {/* Search UI */}
            <div className="flex items-center">
              <p className="block text-xl mr-2 font-medium text-gray-700">
                Search:
              </p>
              <input
                type="text"
                placeholder="Search by name..."
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                className="border border-gray-700 p-[5px] rounded-full sm:w-64 text-center"
              />
            </div>
            <Link
              to="/siteadmin/add-doctor"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Add Doctor
            </Link>
            <div className="mb-4 text-left flex items-left justify-left">
              <label className="block text-lg mr-2 font-medium text-gray-700">
                Sort Order:
              </label>
              <div className="flex items-center space-x-2">
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="asc"
                    checked={sortOrder === "asc"}
                    onChange={() => handleSortOrderChange("asc")}
                    className="form-radio"
                  />
                  <span className="ml-2">Ascending</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="desc"
                    checked={sortOrder === "desc"}
                    onChange={() => handleSortOrderChange("desc")}
                    className="form-radio"
                  />
                  <span className="ml-2">Descending</span>
                </label>
              </div>
            </div>
          </div>
          {/* Doctors Table */}
          <div className="-mx-4 overflow-x-auto">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 table-auto">
                  <thead className="bg-gray-100">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Specialty
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Patients
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {paginatedDoctors.map((doctor) => (
                      <tr key={doctor.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {doctor.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {doctor.specialty || "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            onClick={() => showPatients(doctor.patients)}
                            className="text-blue-600 cursor-pointer"
                          >
                            {doctor.patients.length} Patients
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            onClick={() => handleEditClick(doctor)}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDelete(doctor.id)}
                            className="ml-2 text-red-600 hover:text-red-900"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* Pagination */}
          <div className="mt-6 flex items-center justify-between">
            <span className="text-sm text-gray-500">
              Showing page {currentPage} of {totalPages}
            </span>
            <div className="flex space-x-2">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="text-sm text-gray-500 cursor-pointer"
              >
                Previous
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="text-sm text-gray-500 cursor-pointer"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        {selectedDoctor && (
          <EditDoctorModal
            doctor={selectedDoctor}
            onClose={handleModalClose}
            onUpdate={fetchDoctors}
          />
        )}
        {patientModalOpen && (
          <PatientModal
            patients={selectedPatients}
            onClose={() => setPatientModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default DoctorsList;
