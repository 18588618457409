import axios from "axios";
import React, { useState } from "react";

const EditDoctorModal = ({ doctor, onClose, onUpdate }) => {
  const [editedDoctor, setEditedDoctor] = useState({ ...doctor });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedDoctor((prevDoctor) => ({ ...prevDoctor, [name]: value }));
  };

  const handleUpdate = async () => {
    try {
      axios
        .patch(
          `https://www.report.jamalpurcentralhospital.com/api/doctor/update/${editedDoctor.id}`,
          editedDoctor
        )
        .then((response) => {
          onUpdate(response.data);
          onClose();
        })
        .catch((error) => {
          console.error("Error updating doctor:", error);
        });
    } catch (error) {
      console.error("Error updating doctor:", error);
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50">
      <div className="bg-white p-10 w-full max-w-md rounded">
        <h2 className="text-xl font-semibold mb-4 text-center">Edit Doctor</h2>
        <form className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-600">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={editedDoctor.name}
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">
              Specialty
            </label>
            <input
              type="text"
              name="specialty"
              value={editedDoctor.specialty}
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleUpdate}
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Update
            </button>
            <button
              type="button"
              onClick={onClose}
              className="ml-2 bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDoctorModal;
