import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EditPatientModal from "./EditPatientModal";

const PatientsList = ({ onEdit, onDelete }) => {
  const [patients, setPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const navigate = useNavigate();

  // Memoized fetchPatients function
  const fetchPatients = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://www.report.jamalpurcentralhospital.com/api/patient/all"
      );

      const filteredPatients = response.data.filter((patient) =>
        patient.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      const sortedPatients = filteredPatients.sort((a, b) => {
        const order = sortOrder === "asc" ? 1 : -1;
        return a.name > b.name ? order : -order;
      });

      const startIndex = (currentPage - 1) * 5;
      const endIndex = startIndex + 5;
      const paginatedPatients = sortedPatients.slice(startIndex, endIndex);

      setPatients(paginatedPatients);
      setTotalPages(Math.ceil(sortedPatients.length / 5));
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  }, [currentPage, searchQuery, sortOrder]);

  useEffect(() => {
    fetchPatients();
  }, [currentPage, fetchPatients, searchQuery, sortOrder]);

  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
  }, []);

  const handleSearch = useCallback((query) => {
    setCurrentPage(1);
    setSearchQuery(query);
  }, []);

  const handleSortOrderChange = useCallback((newSortOrder) => {
    setSortOrder(newSortOrder);
  }, []);

  const handleEditClick = useCallback((patient) => {
    setSelectedPatient(patient);
  }, []);

  const handleModalClose = useCallback(() => {
    setSelectedPatient(null);
    fetchPatients(); // Refresh the list after editing
  }, [fetchPatients]);

  const handleManageReportsClick = useCallback(
    (patientId, name) => {
      navigate(`/siteadmin/manage-reports/${patientId}/${name}`);
    },
    [navigate]
  );

  // Memoized table rows to avoid unnecessary re-renders
  const memoizedPatientRows = useMemo(() => {
    return patients.map((patient) => (
      <tr key={patient.id}>
        <td className="px-6 py-4 whitespace-nowrap">{patient.name}</td>
        <td className="px-6 py-4 whitespace-nowrap">{patient.age || "-"}</td>
        <td className="px-6 py-4 whitespace-nowrap">
          {patient.mobileNumber || "-"}
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          {patient.doctors.join(", ") || "-"}
        </td>
        <td className="px-6 py-4 whitespace-nowrap">{patient.email || "-"}</td>
        <td className="px-6 py-4 whitespace-nowrap">
          <button
            onClick={() => handleEditClick(patient)}
            className="text-blue-600 hover:text-blue-900"
          >
            Edit
          </button>
          <button
            onClick={() => onDelete(patient.id)}
            className="ml-2 text-red-600 hover:text-red-900"
          >
            Delete
          </button>
          <button
            onClick={() => handleManageReportsClick(patient.id, patient.name)}
            className="ml-2 text-green-600 hover:text-green-900"
          >
            Manage Reports
          </button>
        </td>
      </tr>
    ));
  }, [patients, handleEditClick, onDelete, handleManageReportsClick]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-10">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-3xl font-semibold mb-8 text-center">
            Patients List
          </h2>

          <div className="mb-6 flex justify-between items-center">
            <div className="flex items-center">
              <p className="block text-xl mr-2 font-medium text-gray-700">
                Search:
              </p>
              <input
                type="text"
                placeholder="Search by name..."
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                className="border border-gray-700 p-[5px] rounded-full sm:w-64 text-center"
              />
            </div>
            <Link
              to="/siteadmin/add-patient"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Add Patient
            </Link>

            <div className="mb-4 text-left flex items-center justify-left">
              <label className="block text-lg mr-2 font-medium text-gray-700">
                Sort Order:
              </label>
              <div className="flex items-center space-x-2">
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="asc"
                    checked={sortOrder === "asc"}
                    onChange={() => handleSortOrderChange("asc")}
                    className="form-radio"
                  />
                  <span className="ml-2">Ascending</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="desc"
                    checked={sortOrder === "desc"}
                    onChange={() => handleSortOrderChange("desc")}
                    className="form-radio"
                  />
                  <span className="ml-2">Descending</span>
                </label>
              </div>
            </div>
          </div>

          <div className="-mx-4 overflow-x-auto">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 table-auto">
                  <thead className="bg-gray-100">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Age
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Mobile Number
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Doctors
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {memoizedPatientRows}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-between">
            <span className="text-sm text-gray-500">
              Showing page {currentPage} of {totalPages}
            </span>
            <div className="flex space-x-2">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="text-sm text-gray-500 cursor-pointer"
              >
                Previous
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="text-sm text-gray-500 cursor-pointer"
              >
                Next
              </button>
            </div>
          </div>
        </div>

        {selectedPatient && (
          <EditPatientModal
            patient={selectedPatient}
            onClose={handleModalClose}
            onUpdate={() => fetchPatients()} // Reload the patient list after update
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(PatientsList);
