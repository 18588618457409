import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import EditPatientModal from "./EditPatientModal";
import PatientsList from "./viewPatients";

const ManagePatient = () => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patients, setPatients] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  // Memoized fetchPatients function
  const fetchPatients = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://www.report.jamalpurcentralhospital.com/api/patient/all"
      );
      setPatients(response.data);
    } catch (error) {
      console.error("Error fetching patients:", error);
      setError("Failed to fetch patients");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPatients();
  }, [fetchPatients]);

  // Memoized handler functions
  const handleEdit = useCallback((patient) => {
    setSelectedPatient(patient);
    setIsEditing(true);
  }, []);

  const handleDelete = useCallback(async (patientId) => {
    try {
      await axios.delete(
        `https://www.report.jamalpurcentralhospital.com/api/patient/${patientId}`
      );
      setPatients((prev) => prev.filter((p) => p.id !== patientId));
    } catch (error) {
      console.error("Error deleting patient:", error);
      setError("Failed to delete patient");
    }
  }, []);

  const handleUpdate = useCallback(
    (updatedPatient) => {
      const updatedPatients = patients.map((patient) =>
        patient.id === updatedPatient.id ? updatedPatient : patient
      );
      setPatients(updatedPatients);
      setIsEditing(false);
    },
    [patients]
  );

  const handleModalClose = useCallback(() => {
    setIsEditing(false);
    setSelectedPatient(null);
  }, []);

  // Memoize the PatientsList component to avoid unnecessary re-renders
  const memoizedPatientsList = useMemo(
    () => (
      <PatientsList
        onEdit={handleEdit}
        onDelete={handleDelete}
        patients={patients}
      />
    ),
    [patients, handleEdit, handleDelete]
  );

  return (
    <div className="p-4">
      {isLoading && <p>Loading...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {!isLoading && memoizedPatientsList}
      {isEditing && selectedPatient && (
        <EditPatientModal
          patient={selectedPatient}
          onClose={handleModalClose}
          onSave={handleUpdate}
        />
      )}
    </div>
  );
};

export default ManagePatient;
