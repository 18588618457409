import { Navigate, Route, Routes } from "react-router-dom";
import AddDoctor from "./Component/admin/AddDoctor/addDoctor";
import FindAndUpdatePatient from "./Component/admin/AddReport/addReport";
import ManageReports from "./Component/admin/AddReport/manageReports";
import ViewDoctor from "./Component/admin/Doctors/viewDoctors";
import AddPatients from "./Component/admin/addPatients/addPatients";
import AdminDashboard from "./Component/admin/dashboard/AdminDashboard";
import AdminSidebar from "./Component/admin/dashboard/AdminSidebar";
import AdminLoginForm from "./Component/admin/login";
import AdminRegistrationForm from "./Component/admin/register";
import ManagePatient from "./Component/admin/viewPatients/ManagePatients";
import PatientLists from "./Component/admin/viewPatients/viewPatients";
import Navbar from "./Component/navbar/navbar";
import Profile from "./Component/user/Profile/profile";
import DownloadReport from "./Component/user/Report/downloadReports";
import UserDashboard from "./Component/user/dashboard/userDashboard";
import UserLogin from "./Component/user/login/userLogin";
import ViewAppointments from "./Component/user/viewAppointment/viewAppointments";
import { useUser } from "./Contexts/UserContext";

function App() {
  const { state } = useUser();

  return (
    <div className="overflow-x-clip">
      <Navbar />
      <div className="pt-16"></div>
      <Routes>
        {state.isAuthenticated ? (
          <>
            {state.role === "admin" && (
              <Route path="/siteadmin/*" element={<AdminSidebar />}>
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="add-patient" element={<AddPatients />} />
                <Route path="view-patients" element={<PatientLists />} />
                <Route path="manage" element={<ManagePatient />} />
                <Route path="add-doctor" element={<AddDoctor />} />
                <Route path="view-doctor" element={<ViewDoctor />} />
                <Route path="manage-reports/:patientId/:name" element={<ManageReports />} /> {/* Dynamic route */}
                <Route path="add-report" element={<FindAndUpdatePatient />} />
              </Route>
            )}

            {state.role === "user" && (
              <>
                <Route path="/user/dashboard" element={<UserDashboard />} />
                <Route path="/user/download-report" element={<DownloadReport />} />
                <Route path="/user/view-appointment" element={<ViewAppointments />} />
                <Route path="/user/profile" element={<Profile />} />
              </>
            )}

            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        ) : (
          <>
            <Route path="/siteadmin/register" element={<AdminRegistrationForm />} />
            <Route path="/siteadmin/login" element={<AdminLoginForm />} />
            <Route path="/user/login" element={<UserLogin />} />
            <Route path="*" element={<Navigate to="/user/login" replace />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
