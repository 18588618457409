/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import imagery from "../../Assets/hospital.jpg";
import { SET_USER, useUser } from "../../Contexts/UserContext";
const AdminLoginForm = () => {
  const [adminData, setAdminData] = useState({
    username: "",
    password: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();
  const { dispatch } = useUser();
  const [loginMessage, setLoginMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminData({ ...adminData, [name]: value });

    // Real-time validation
    const errors = { ...validationErrors };
    if (name === "username") {
      errors.username = value.trim() === "" ? "Username is required" : "";
    } else if (name === "password") {
      errors.password = value.trim() === "" ? "Password is required" : "";
    }
    setValidationErrors(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation check before submitting
    if (adminData.username.trim() === "" || adminData.password.trim() === "") {
      setValidationErrors({
        username:
          adminData.username.trim() === "" ? "Username is required" : "",
        password:
          adminData.password.trim() === "" ? "Password is required" : "",
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://www.report.jamalpurcentralhospital.com/api/admin/login",
        adminData
      );

      if (response.data.message === "Login successful") {
        // Set the user data and role in the context after successful login
        dispatch({
          type: SET_USER,
          payload: { user: response.data.user, role: "admin" },
        });

        // Handle the successful login, e.g., navigate to the admin dashboard
        console.log("Admin login successful");
        setLoginMessage("Login successful");
        navigate("/siteadmin/dashboard");
      } else {
        // Handle login failure, e.g., display an error message
        console.error("Admin login failed");
        setLoginMessage("Login failed");
      }
    } catch (error) {
      // Handle login failure due to network or server issues
      console.error("Admin login failed", error);
      setLoginMessage("Login failed");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="flex flex-col lg:flex-row bg-white shadow-lg rounded-lg overflow-hidden w-full max-w-4xl">
        {/* Left section (Form) */}
        <div className="w-full lg:w-1/2 p-8 md:p-12">
          <h2 className="text-3xl font-semibold mb-4 text-gray-800">Sign in</h2>
          <p className="text-sm text-gray-600 mb-6">
            Enter your username and password to login
          </p>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
              >
                Username:
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={adminData.username}
                onChange={handleChange}
                className={`w-full mt-1 p-2 border rounded ${
                  validationErrors.username && "border-red-500"
                }`}
              />
              {validationErrors.username && (
                <p className="text-red-500 text-xs mt-1">
                  {validationErrors.username}
                </p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password:
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={adminData.password}
                onChange={handleChange}
                className={`w-full mt-1 p-2 border rounded ${
                  validationErrors.password && "border-red-500"
                }`}
              />
              {validationErrors.password && (
                <p className="text-red-500 text-xs mt-1">
                  {validationErrors.password}
                </p>
              )}
            </div>
            <div className="flex items-center justify-between mb-6">
              <div>
                <input type="checkbox" id="rememberMe" className="mr-2" />
                <label htmlFor="rememberMe" className="text-sm text-gray-600">
                  Remember me for 30 days
                </label>
              </div>
              <a href="#" className="text-sm text-blue-500 hover:underline">
                Forgot password?
              </a>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-150"
            >
              Login
            </button>
          </form>
          <p className="text-sm text-center mt-4 text-gray-500">
            Need an account?{" "}
            <a
              href="/siteadmin/register"
              className="text-blue-500 hover:underline"
            >
              Sign up here
            </a>
          </p>
          <p className="mt-4 text-red-500 text-center">{loginMessage}</p>
        </div>

        {/* Right section (Illustration/Info) */}
        <div className="hidden lg:flex lg:w-1/2 items-center justify-center bg-gradient-to-r from-blue-500 to-indigo-600 text-white">
          <div className=" text-center">
            <img
              alt="hospital"
              src={imagery}
              className="w-[400px] h-auto rounded-xl"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginForm;
