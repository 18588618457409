import React, { useRef } from "react";

const UploadSection = ({
  doctors,
  selectedDoctor,
  setSelectedDoctor,
  fileName,
  setFileName,
  handleFileDrop,
  handleFileUpload,
  handleReportUpload,
  uploadProgress,
  uploadedFiles,
}) => {
  const fileInputRef = useRef(null);

  const handleAreaClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    handleFileUpload(e);
  };

  return (
    <div className="upload-section mt-8">
      <label className="block mb-2 text-sm font-medium text-gray-700">
        Select Doctor:
      </label>
      <div className="mb-4">
        <select
          value={selectedDoctor}
          onChange={(e) => setSelectedDoctor(e.target.value)}
          className="form-select mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        >
          <option value="" disabled>
            Select a doctor
          </option>
          {doctors.map((doctor) => (
            <option key={doctor.id} value={doctor.name}>
              {doctor.name}
            </option>
          ))}
        </select>
      </div>

      <label className="block mb-2 text-sm font-medium text-gray-700">
        File Name:
      </label>
      <input
        type="text"
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
        className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-4"
        placeholder="Enter file name"
      />

      <div
        className="drag-drop-area border-2 border-dashed p-6 text-center bg-white rounded-lg cursor-pointer"
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          handleFileDrop(e);
        }}
        onClick={handleAreaClick}
      >
        <p className="text-gray-600">
          Drag & Drop to Upload Reports <br /> or Click to Select Files
        </p>
        <input
          type="file"
          multiple
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileChange}
        />
      </div>

      {/* Display uploaded files */}
      {uploadedFiles && uploadedFiles.length > 0 && (
        <div className="mt-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Uploaded Files:
          </label>
          <ul className="list-disc list-inside bg-gray-100 p-4 rounded-lg">
            {uploadedFiles.map((file, index) => (
              <li key={index} className="text-sm text-gray-800">
                {file.name}
              </li>
            ))}
          </ul>
        </div>
      )}

      {uploadProgress > 0 && (
        <div className="mt-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Upload Progress:
          </label>
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                  {uploadProgress}%
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
              <div
                style={{ width: `${uploadProgress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
              ></div>
            </div>
          </div>
        </div>
      )}

      <button
        onClick={handleReportUpload}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
      >
        Upload Reports
      </button>
    </div>
  );
};

export default UploadSection;
