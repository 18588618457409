import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AddPatientForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    mobileNumber: "",
    email: "",
    doctorNames: [],
  });
  const [doctors, setDoctors] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the list of doctors from the backend
    const fetchDoctors = async () => {
      try {
        const response = await axios.get(
          "https://www.report.jamalpurcentralhospital.com/api/doctor"
        );
        setDoctors(response.data);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };

    fetchDoctors();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDoctorSelection = (e) => {
    const { value, checked } = e.target;

    setFormData((prevData) => {
      const updatedDoctorNames = checked
        ? [...prevData.doctorNames, value]
        : prevData.doctorNames.filter((doctorName) => doctorName !== value);

      return { ...prevData, doctorNames: updatedDoctorNames };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://www.report.jamalpurcentralhospital.com/api/patient/add",
        formData
      );

      if (response.status === 201 || response.status === 200) {
        setSuccessMessage("Patient added successfully!");
        setFormData({
          name: "",
          age: "",
          mobileNumber: "",
          email: "",
          doctorNames: [],
        });
        setIsDropdownOpen(false); // Close the dropdown after submission
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An error occurred while adding the patient.");
      }
    }
  };

  const handleBackClick = () => {
    navigate("/siteadmin/view-patients"); // Adjust the route as necessary
  };

  return (
    <div className="max-w-lg mx-auto mt-10 p-8 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-semibold mb-6 text-center text-gray-700">
        Add New Patient
      </h2>

      {successMessage && (
        <p className="text-green-500 text-center mb-4">{successMessage}</p>
      )}
      {errorMessage && (
        <p className="text-red-500 text-center mb-4">{errorMessage}</p>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-semibold text-gray-700 mb-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="appearance-none border rounded w-full py-3 px-4 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-semibold text-gray-700 mb-2">
            Age
          </label>
          <input
            type="text"
            id="age"
            name="age"
            value={formData.age}
            onChange={handleChange}
            className="appearance-none border rounded w-full py-3 px-4 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div>
          <label className="block text-sm font-semibold text-gray-700 mb-2">
            Mobile Number
          </label>
          <input
            type="text"
            id="mobileNumber"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleChange}
            className="appearance-none border rounded w-full py-3 px-4 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-semibold text-gray-700 mb-2">
            Email (Optional)
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="appearance-none border rounded w-full py-3 px-4 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div>
          <label className="block text-sm font-semibold text-gray-700 mb-2">
            Select Doctors
          </label>
          <div className="relative">
            <button
              type="button"
              className="appearance-none border rounded w-full py-3 px-4 leading-tight focus:outline-none focus:shadow-outline text-left"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {formData.doctorNames.length > 0
                ? `${formData.doctorNames.length} doctor(s) selected`
                : "Select Doctors"}
            </button>

            {isDropdownOpen && (
              <div className="absolute mt-2 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                <div className="max-h-48 overflow-y-auto">
                  {doctors.map((doctor) => (
                    <label
                      key={doctor.id}
                      className="flex items-center px-3 py-2 hover:bg-gray-100"
                    >
                      <input
                        type="checkbox"
                        value={doctor.name}
                        checked={formData.doctorNames.includes(doctor.name)}
                        onChange={handleDoctorSelection}
                        className="mr-2"
                      />
                      {doctor.name}
                    </label>
                  ))}
                </div>
                <button
                  type="button"
                  className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 rounded-b focus:outline-none focus:shadow-outline"
                  onClick={() => setIsDropdownOpen(false)}
                >
                  Done
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={handleBackClick}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Back
          </button>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Add Patient
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddPatientForm;
