import axios from "axios"; // Ensure Axios is installed and imported
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import avatar from "../../../Assets/user.png";
import { useUser } from "../../../Contexts/UserContext";
import Report from "../Report/downloadReports"; // Assuming this component handles report download

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UserDashboard = () => {
  const { state } = useUser();
  const [doctors, setDoctors] = useState([]);
  const [reports, setReports] = useState([]);
  const [reportStats, setReportStats] = useState({});

  // Function to format the current date and time
  const formatDateTime = () => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date().toLocaleString("en-US", options);
  };

  useEffect(() => {
    // Fetch doctors data
    axios
      .get(`https://www.report.jamalpurcentralhospital.com/api/patient/doctors/${state.user.id}`)
      .then((response) => setDoctors(response.data))
      .catch((error) => console.error("Error fetching doctors:", error));

    // Fetch reports data
    axios
      .get(
        `https://www.report.jamalpurcentralhospital.com/api/report/patient/${state.user.id}`
      )
      .then((response) => {
        setReports(response.data);
        const stats = calculateReportStats(response.data);
        setReportStats(stats);
      })
      .catch((error) => console.error("Error fetching reports:", error));
  }, [state.user.id]);

  // Calculate report statistics (dummy function)
  const calculateReportStats = (reports) => {
    const reportCountByMonth = {};
    reports.forEach((report) => {
      const month = new Date(report.reportDate).toLocaleString("default", {
        month: "long",
      });
      if (!reportCountByMonth[month]) {
        reportCountByMonth[month] = 0;
      }
      reportCountByMonth[month]++;
    });
    return reportCountByMonth;
  };

  // Prepare data for the chart
  const chartData = {
    labels: Object.keys(reportStats),
    datasets: [
      {
        label: "Reports Generated",
        backgroundColor: "#4C51BF",
        borderColor: "#4C51BF",
        data: Object.values(reportStats),
      },
    ],
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 md:p-10 space-y-6">
      {/* Welcome Section */}
      <div className="bg-white shadow-sm rounded-lg p-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div>
            <h2 className="text-2xl font-semibold text-gray-800">
              Welcome, {state.user.name}!
            </h2>
            <p className="text-gray-500">{formatDateTime()}</p>
          </div>
        </div>
      </div>

      {/* Profile Section */}
      <div className="bg-white shadow-sm rounded-lg p-6 flex flex-col md:flex-row items-center md:items-start">
        <img
          src={avatar}
          alt="User Avatar"
          className="rounded-full w-24 h-24 mb-4 md:mb-0 md:mr-6 border border-gray-200"
        />
        <div className="flex flex-col text-center md:text-left">
          <p className="capitalize text-xl font-semibold text-gray-800 mb-2">
            {state.user.name}
          </p>
          <p className="text-gray-500 mb-1">
            <strong>Email:</strong> {state.user.email}
          </p>
          <p className="text-gray-500">
            <strong>Phone:</strong> {state.user.mobileNumber}
          </p>
        </div>
      </div>

      {/* Reports Section (Main Focus) */}
      <div className="bg-white shadow-sm rounded-lg p-6">
        <h3 className="text-xl font-semibold text-gray-800 mb-4">
          Download Your Report
        </h3>
        <Report reports={reports} />
      </div>

      {/* Dummy Statistics Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Card for Report Statistics */}
        <div className="bg-white shadow-sm rounded-lg p-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Reports Generated
          </h3>
          <Bar data={chartData} />
        </div>

        {/* Card for List of Doctors */}
        <div className="bg-white shadow-sm rounded-lg p-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Your Doctors
          </h3>
          <ul>
            {doctors.map((doctor, index) => (
              <li
                key={index}
                className="border-b border-gray-200 py-2 flex justify-between"
              >
                <span>{doctor.name}</span>
                <span className="text-gray-500">{doctor.specialty}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
