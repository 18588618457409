import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import illustration from "../../../Assets/hospital.jpg";
import { SET_USER, useUser } from "../../../Contexts/UserContext";

const UserLogin = () => {
  const { state, dispatch } = useUser();
  const [user, setUser] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill("")); // Using an array for OTP input fields
  const [isGenerated, setIsGenerated] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [isValid, setIsValid] = useState(false);
  const [counter, setCounter] = useState(0); // Start counter at 0 initially
  const [errors, setErrors] = useState({ phoneNumber: "", otp: "" });
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(""); // "success" or "error"
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (counter > 0) {
      const countdown = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);

      return () => clearInterval(countdown);
    } else {
      setIsValid(false); // Allow OTP to be resent
    }
  }, [counter]);

  const handleSendOtp = async () => {
    try {
      if (phoneNumber) {
        const response = await axios.get(
          `https://www.report.jamalpurcentralhospital.com/api/patient/phone/${phoneNumber}`
        );

        if (response.data.id) {
          setPatientId(response.data.id);
          setUser(response.data); // Set user data after fetching it
          handlegenerateOtp(response.data.id);
        } else {
          alert("User not found. Please Contact Support");
        }
      } else {
        alert("Phone Number not valid");
      }
    } catch (error) {
      console.error("Error making the first API request:", error);
      if (error.code === "ERR_BAD_REQUEST") {
        alert("Invalid Phone number. Please Contact Support");
      }
    }
  };

  const handlegenerateOtp = async (patientId) => {
    try {
      const res = await axios.post(
        `https://www.report.jamalpurcentralhospital.com/api/otp/generate/${patientId}`
      );

      if (res.data.message === "OTP generated successfully") {
        setIsValid(true);
        setIsGenerated(true);
        setCounter(30); // Set counter to 30 seconds
        setMessage("OTP has been sent to your phone.");
        setMessageType("success");
      } else {
        setMessage("Error generating OTP. Please contact support.");
        setMessageType("error");
      }
    } catch (error) {
      console.error("Error generating OTP:", error);
      setMessage("Error generating OTP. Please contact support.");
      setMessageType("error");
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus on next input
      if (value !== "" && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }

      setErrors({ ...errors, otp: "" }); // Clear OTP errors if any
    }
  };

  const handleVerifyOtp = async () => {
    if (otp.some((digit) => digit === "")) {
      setErrors({ ...errors, otp: "Please enter the complete OTP." });
      return;
    }

    const fullOtp = otp.join(""); // Join OTP digits without any separators
    setLoading(true);
    try {
      const response = await axios.get(
        `https://www.report.jamalpurcentralhospital.com/api/otp/verifyby/${patientId}/${fullOtp}`
      );

      if (response.data.message === "OTP is valid") {
        dispatch({
          type: SET_USER,
          payload: { user, role: "user" },
        });

        navigate("/user/dashboard");
      } else {
        setMessage("Invalid OTP. Please try again.");
        setMessageType("error");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setMessage("Error verifying OTP. Please try again.");
      setMessageType("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      {state.isAuthenticated ? (
        <div>
          <p>User is authenticated. Redirecting...</p>
          {navigate("/user/dashboard")}
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row bg-white shadow-lg items-center justify-center rounded-lg overflow-hidden w-full max-w-4xl">
          <div className="w-full lg:w-1/2 p-8 md:p-12">
            {!isGenerated ? (
              <div>
                <h2 className="text-3xl font-semibold mb-4 text-center">
                  Log in
                </h2>
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className={`w-full p-3 border rounded mb-2 ${
                    errors.phoneNumber ? "border-red-500" : ""
                  }`}
                />
                {errors.phoneNumber && (
                  <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
                )}
                <button
                  onClick={handleSendOtp}
                  className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-150 mt-4"
                  disabled={loading} // Disable button while loading
                >
                  {loading ? "Sending..." : "Get Code"}
                </button>
                {message && (
                  <p
                    className={`mt-4 text-center ${
                      messageType === "success"
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {message}
                  </p>
                )}
              </div>
            ) : (
              <div className="mt-4">
                <h2 className="text-2xl font-semibold mb-4 text-center">
                  Enter OTP
                </h2>
                <div className="flex justify-between mb-4">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      id={`otp-${index}`}
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      className={`w-12 h-12 text-center text-2xl border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                        errors.otp ? "border-red-500" : ""
                      }`}
                    />
                  ))}
                </div>
                {errors.otp && (
                  <p className="text-red-500 text-sm mb-4">{errors.otp}</p>
                )}
                <button
                  onClick={handleVerifyOtp}
                  className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600 transition duration-150"
                  disabled={loading} // Disable button while loading
                >
                  {loading ? "Verifying..." : "Verify & Login"}
                </button>
                {message && (
                  <p
                    className={`mt-4 text-center ${
                      messageType === "success"
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {message}
                  </p>
                )}
                {counter > 0 ? (
                  <p className="text-sm text-gray-500 mt-4 text-center">
                    Resend OTP in {counter} seconds
                  </p>
                ) : (
                  <p className="text-sm text-gray-500 mt-4 text-center">
                    Didn’t receive a code?{" "}
                    <button
                      onClick={handleSendOtp}
                      className="text-blue-500 hover:underline"
                    >
                      Resend OTP
                    </button>
                  </p>
                )}
              </div>
            )}
          </div>

          {/* Right section (Illustration) */}
          <div className="hidden lg:flex lg:w-1/2 py-24 items-center justify-center bg-gradient-to-r from-blue-500 to-indigo-600 text-white">
            <img
              src={illustration}
              alt="Illustration"
              className="w-[400px] h-auto rounded-xl"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserLogin;
