import React from "react";
import { useNavigate } from "react-router-dom";
import { LOGOUT, useUser } from "../../Contexts/UserContext";

import logo from "../../Assets/site.svg";

const Navbar = () => {
  const { state, dispatch } = useUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the user from localStorage
    localStorage.removeItem("user");

    // Dispatch the logout action
    dispatch({ type: LOGOUT });

    // Redirect based on the role
    if (state.role === "admin") {
      navigate("/siteadmin/login");
    } else if (state.role === "user") {
      navigate("/user/login");
    } else {
      navigate("/");
    }
  };

  return (
    <div className="bg-[#2A3948] px-2 md:px-32 flex justify-between items-center fixed top-0 left-0 right-0 z-50 h-16">
      <img src={logo} alt="Logo" className="h-full w-48" />
      {state.isAuthenticated && (
        <button
          onClick={handleLogout}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
        >
          Logout
        </button>
      )}
    </div>
  );
};

export default Navbar;
