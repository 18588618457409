import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReportItem from "./ReportItem";
import UploadSection from "./UploadSection";

const ManageReports = () => {
  const { patientId, name } = useParams();
  const [reports, setReports] = useState([]);
  const [newReports, setNewReports] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [fileName, setFileName] = useState("");
  const [doctors, setDoctors] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate();

  // Memoized function to fetch reports
  const fetchReports = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://www.report.jamalpurcentralhospital.com/api/report/patient/${patientId}`
      );
      setReports(response.data);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage("Failed to load reports");
      console.error("Error fetching reports:", error);
    }
  }, [patientId]);

  // Memoized function to fetch doctors
  const fetchDoctors = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://www.report.jamalpurcentralhospital.com/api/doctor"
      );
      setDoctors(response.data);
    } catch (error) {
      setErrorMessage("Failed to load doctors");
      console.error("Error fetching doctors:", error);
    }
  }, []);

  useEffect(() => {
    fetchReports();
    fetchDoctors();
  }, [fetchReports, fetchDoctors]);

  const handleFileDrop = useCallback((e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setNewReports(files);
  }, []);

  const handleFileUpload = useCallback((e) => {
    const files = Array.from(e.target.files);
    setNewReports(files);
  }, []);

  const handleReportUpload = useCallback(async () => {
    if (!selectedDoctor || !fileName || newReports.length === 0) {
      setErrorMessage("Doctor name, file name, and report file are required");
      return;
    }

    const formData = new FormData();
    newReports.forEach((report) => formData.append("reports", report));

    try {
      await axios.post(
        `https://www.report.jamalpurcentralhospital.com/api/report/upload-multiple/${patientId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          params: {
            doctorName: selectedDoctor,
            fileName,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        }
      );

      setSuccessMessage("Reports uploaded successfully");
      setNewReports([]);
      setFileName("");
      setSelectedDoctor("");
      setUploadProgress(0);

      // Fetch the updated reports immediately after upload
      fetchReports();
    } catch (error) {
      setErrorMessage("Failed to upload reports");
      console.error("Error uploading reports:", error);
    }
  }, [selectedDoctor, fileName, newReports, fetchReports, patientId]);

  const handleReportDelete = useCallback(async (reportId) => {
    try {
      await axios.delete(
        `https://www.report.jamalpurcentralhospital.com/api/report/delete/${reportId}`
      );
      setReports((prevReports) =>
        prevReports.filter((report) => report.id !== reportId)
      );
      setSuccessMessage("Report deleted successfully");
    } catch (error) {
      setErrorMessage("Failed to delete report");
      console.error("Error deleting report:", error);
    }
  }, []);

  const handleReportEdit = useCallback(
    async (updatedReport) => {
      const formData = new FormData();

      if (updatedReport.newFile) {
        formData.append("report", updatedReport.newFile);
      }

      formData.append("fileName", updatedReport.fileName);
      formData.append("doctorName", updatedReport.doctor.name);

      try {
        await axios.patch(
          `https://www.report.jamalpurcentralhospital.com/api/report/update/${updatedReport.id}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        setSuccessMessage("Report updated successfully");

        // Refresh reports after update
        fetchReports();
      } catch (error) {
        setErrorMessage("Failed to update report");
        console.error("Error updating report:", error);
      }
    },
    [fetchReports]
  );

  const handleBackClick = useCallback(() => {
    navigate("/siteadmin/view-patients");
  }, [navigate]);

  return (
    <div className="manage-reports max-w-5xl mx-auto mt-8 p-6 pb-6 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="text-3xl font-semibold mb-6 text-center">
        {name}'s Reports
      </h2>

      {errorMessage && (
        <p className="text-red-500 text-center mb-4">{errorMessage}</p>
      )}
      {successMessage && (
        <p className="text-green-500 text-center mb-4">{successMessage}</p>
      )}

      <div className="existing-reports">
        {isLoading ? (
          <p>Loading reports...</p>
        ) : reports.length > 0 ? (
          reports.map((report) => (
            <ReportItem
              key={report.id}
              report={report}
              doctors={doctors}
              onDelete={handleReportDelete}
              onSave={handleReportEdit}
              patientId={patientId}
            />
          ))
        ) : (
          <p className="text-gray-400 text-center">No reports found.</p>
        )}
      </div>

      <hr className="my-8" />

      <UploadSection
        doctors={doctors}
        selectedDoctor={selectedDoctor}
        setSelectedDoctor={setSelectedDoctor}
        fileName={fileName}
        setFileName={setFileName}
        handleFileDrop={handleFileDrop}
        handleFileUpload={handleFileUpload}
        handleReportUpload={handleReportUpload}
        uploadProgress={uploadProgress}
        uploadedFiles={newReports}
      />

      <button
        onClick={handleBackClick}
        className="mt-6 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
      >
        Back to Patient List
      </button>
    </div>
  );
};

export default ManageReports;
