import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";

const AdminDashboard = () => {
  const [statistics, setStatistics] = useState({
    totalPatients: 0,
    totalDoctors: 0,
  });
  const [chartData, setChartData] = useState({
    labels: ["Patients", "Doctors"],
    datasets: [
      {
        label: "Counts",
        backgroundColor: ["#4C51BF", "#00C897"],
        data: [0, 0],
      },
    ],
  });
  const [loading, setLoading] = useState(true);

  // Memoized function to format the current date and time
  const formatDateTime = useMemo(() => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date().toLocaleString("en-US", options);
  }, []);

  // Memoized function to fetch statistics and chart data from the API
  const fetchStatistics = useCallback(async () => {
    try {
      const patientsResponse = await axios.get(
        "https://www.report.jamalpurcentralhospital.com/api/patient/count"
      );

      const doctorsResponse = await axios.get(
        "https://www.report.jamalpurcentralhospital.com/api/doctor/count"
      );

      const totalPatients = patientsResponse.data.count || 0;
      const totalDoctors = doctorsResponse.data.count || 0;

      setStatistics({
        totalPatients,
        totalDoctors,
      });

      // Update chart data with the fetched statistics
      setChartData({
        labels: ["Patients", "Doctors"],
        datasets: [
          {
            label: "Counts",
            backgroundColor: ["#4C51BF", "#00C897"],
            data: [totalPatients, totalDoctors],
          },
        ],
      });

      setLoading(false); // Set loading to false after data is loaded
    } catch (error) {
      console.error("Error fetching statistics:", error);
      setLoading(false); // Set loading to false even if there's an error
    }
  }, []);

  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  return (
    <div className="min-h-screen bg-gray-100 p-4 md:p-10 flex md:max-w-[95%] flex-col space-y-6">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <p>Loading...</p>
        </div>
      ) : (
        <>
          {/* Welcome Section */}
          <div className="bg-white shadow-sm rounded-lg p-6 flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <h2 className="text-2xl font-semibold text-gray-800">
                Welcome, Admin
              </h2>
              <p className="text-gray-500">{formatDateTime}</p>
            </div>
          </div>

          {/* Statistics Section */}
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6">
            <div className="bg-white shadow-sm rounded-lg p-6 flex-1">
              <h3 className="text-lg font-semibold text-gray-800">
                Total Patients
              </h3>
              <p className="text-3xl font-bold text-blue-600">
                {statistics.totalPatients}
              </p>
            </div>
            <div className="bg-white shadow-sm rounded-lg p-6 flex-1">
              <h3 className="text-lg font-semibold text-gray-800">
                Total Doctors
              </h3>
              <p className="text-3xl font-bold text-green-600">
                {statistics.totalDoctors}
              </p>
            </div>
          </div>

          {/* Chart Section */}
          <div className="bg-white shadow-sm rounded-lg p-6 flex-1">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Patients vs. Doctors Overview
            </h3>
            {chartData.labels.length > 0 ? (
              <Bar data={chartData} />
            ) : (
              <p>No data available to display chart.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AdminDashboard;
